import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuard, throwIfAlreadyLoaded } from '@core/guards';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    imports: [AngularSvgIconModule.forRoot()],
    exports: [],
    providers: [AuthGuard]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
