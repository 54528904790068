import { Component, OnInit } from '@angular/core';
import { MoneyValueModel } from '@shared/models';
import { IMenuItemExtended } from '@shared/interfaces';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { MessagesService } from '@services/messages.service';

@Component({
    selector: 'app-ui-demo',
    templateUrl: './ui-demo.component.html',
    styleUrls: ['./ui-demo.component.scss']
})
export class UiDemoComponent implements OnInit {
    splitButtonItems: IMenuItemExtended[] = [];
    numberValue = 0;
    currency: MoneyValueModel = new MoneyValueModel();
    dropdownMock: { label: string; value: string }[] = [];
    checkbox = false;

    constructor(protected messages: MessagesService) {}

    testSuccess() {
        this.messages.showSuccess('MESSAGE => Success', true);
    }
    testError() {
        this.messages.showError('MESSAGE => Error ... Very Very Very Long Message', true);
    }
    testWarn() {
        this.messages.showWarn('MESSAGE => Warn', true);
    }
    testInfo() {
        this.messages.showInfo('MESSAGE => INFO', true);
    }

    checkboxChange(event: CheckboxChangeEvent) {
        console.log(event);
    }

    ngOnInit() {
        this.splitButtonItems = [
            {
                label: 'Importieren',
                icon: 'pi pi-upload'
            }
        ];

        this.currency = Object.assign(new MoneyValueModel(), {
            value: 100,
            currencyCode: 'EUR'
        });
        this.dropdownMock = [
            {
                label: 'Option 1',
                value: '1'
            },
            {
                label: 'Option 2',
                value: '2'
            },
            {
                label: 'Option 3',
                value: '3'
            },
            {
                label: 'Option 4',
                value: '4'
            },
            {
                label: 'Option 5',
                value: '5'
            }
        ];
    }
}
