import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AuthErrors, TokenInterceptorMessages } from '@shared/enum';
import { SessionModel } from '@shared/models';
import { MessagesService } from '@services/messages.service';
import { AuthService } from '@services/auth.service';
import { BaseErrorHandler } from '@core/interceptors/base.error-handler';

@Injectable()
export class TokenRefreshInterceptor extends BaseErrorHandler implements HttpInterceptor {
    protected _isRefreshing = false;
    protected _refreshQueue: any[] = [];
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        protected override messageService: MessagesService,
        private authService: AuthService
    ) {
        super(messageService);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        return this.handleBadRequest(request, next, error);
                    case 401:
                        return this.handleUnauthorized(request, next, error);
                    default:
                        return throwError(() => error);
                }
            })
        );
    }

    // 400
    protected handleBadRequest(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        const errorCases = [TokenInterceptorMessages.TokenMissing, TokenInterceptorMessages.TokenFormatWrong];
        if (errorCases.includes(error.error)) return this.authService.handleRemoveSession(error);
        return throwError(() => new Error(error.error));
    }

    // 401
    protected handleUnauthorized(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        if (error.error === TokenInterceptorMessages.SessionExpired) return this.tryToRefresh(request, next);
        if (error.error === TokenInterceptorMessages.NoSessionForToken) return this.authService.handleRemoveSession(error);
        if (error.error === AuthErrors.Wrong_Credentials) this.showErrorToast(error.error);
        return throwError(() => new Error(error.error));
    }

    // 403
    protected handleForbidden(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        return throwError(() => new Error(error.error));
    }

    private tryToRefresh(request: HttpRequest<any>, next: HttpHandler) {
        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.sessionRefresh().pipe(
                switchMap((session: SessionModel) => {
                    const { token } = session;
                    this._isRefreshing = false;
                    this.refreshTokenSubject.next(token);
                    this.retryQueuedRequests(token);
                    return next.handle(this.addToken(request, token));
                }),
                catchError((err) => {
                    this._isRefreshing = false;
                    this.authService.removeSession();
                    return throwError(() => err);
                })
            );
        } else {
            const subject = new Subject<any>();
            this._refreshQueue.push({ request, next, subject });
            return subject.asObservable();
        }
    }

    private retryQueuedRequests(token: string) {
        this._refreshQueue.forEach(({ request, next, subject }, index) => {
            next.handle(this.addToken(request, token)).subscribe({
                next: (res: any) => {
                    subject.next(res);
                    subject.complete();
                    this._refreshQueue.splice(index, 1);
                },
                error: console.error
            });
        });
    }

    private addToken(request: HttpRequest<any>, token: string) {
        if (token) {
            const headers = request.headers ? request.headers : new HttpHeaders();
            const clonedHeaders = headers.set('x-session-token', token);
            return request.clone({
                headers: clonedHeaders
            });
        }
        return request;
    }
}
