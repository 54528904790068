<section class="w-full h-full _module-content">
    <section class="bg-white p-4">
        <h3>Messages</h3>
        <section class="grid align-items-center justify-content-start mt-4">
            <app-epx-button-stack>
                <button (click)="testSuccess()" pButton class="epx-button epx-button-success" label="Primary Success"></button>
                <button (click)="testError()" pButton class="epx-button epx-button-error" label="Primary Error"></button>
                <button (click)="testWarn()" pButton class="epx-button epx-button-warn" label="Primary Warn"></button>
                <button (click)="testInfo()" pButton class="epx-button epx-button-info" label="Primary Info"></button>
            </app-epx-button-stack>
        </section>
    </section>
    <section class="bg-white p-4">
        <h3>Buttons</h3>
        <section class="grid align-items-center justify-content-start mt-4">
            <h5 class="col-2">Active</h5>
            <div class="col-8 flex flex-row gap-2">
                <button pButton class="epx-button" label="Primary Button"></button>
                <button pButton class="epx-button epx-button-secondary" label="Secondary Button"></button>
                <button pButton class="epx-button epx-button-text" label="Text Button"></button>
            </div>
        </section>
        <section class="grid align-items-center justify-content-start mt-2">
            <h5 class="col-2">Disabled</h5>
            <div class="col-8 flex flex-row gap-2">
                <button disabled pButton class="epx-button" label="Primary Button"></button>
                <button disabled pButton class="epx-button epx-button-secondary" label="Secondary Button"></button>
                <button disabled pButton class="epx-button epx-button-text" label="Text Button"></button>
            </div>
        </section>
        <section class="grid align-items-center justify-content-start mt-4">
            <h5 class="col-2">Icons</h5>
            <div class="col-8 flex flex-row gap-2">
                <p-button styleClass="epx-button" label="Icon Left" iconPos="left">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button" label="Icon Right" iconPos="right">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-secondary" label="Icon Left" iconPos="left">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-secondary" label="Icon Right" iconPos="right">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
            </div>
        </section>

        <section class="grid align-items-center justify-content-start mt-2">
            <h5 class="col-2">Action</h5>
            <div class="col-10 flex flex-row gap-2">
                <p-button styleClass="epx-button epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon [size]="16" icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-secondary epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Secondary" [size]="21" icon="plus"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-secondary epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Secondary" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-text epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Secondary" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-text epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Success" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-text epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Error" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-text epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Warning" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-text epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Info" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-success epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Success" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
                <p-button styleClass="epx-button epx-button-error epx-icon-only-btn">
                    <ng-template pTemplate="icon">
                        <epx-svg-icon color="Error" [size]="21" icon="trash"></epx-svg-icon>
                    </ng-template>
                </p-button>
            </div>
            <h5 class="col-2">Split Button</h5>
            <div class="col-10 flex flex-row gap-2">
                <p-splitButton styleClass="epx-split-button epx-button-secondary" [model]="splitButtonItems" [label]="''" icon="pi pi-plus"></p-splitButton>
            </div>
        </section>
    </section>

    <section class="bg-white p-4 mt-4">
        <h3>Inputs</h3>
        <section class="grid align-items-center justify-content-start mt-5">
            <h5 class="col-2">Basic</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-4">
                        <input pInputText type="text" placeholder="Basic" />
                    </div>
                    <div class="col-4">
                        <input [disabled]="true" pInputText type="text" placeholder="Disabled" />
                    </div>
                </div>
            </div>
        </section>
        <section class="grid align-items-center justify-content-start mt-5">
            <h5 class="col-2">Number</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-4">
                        <p-inputNumber styleClass="epx-input-number" [(ngModel)]="numberValue" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-4">
                        <p-inputNumber styleClass="epx-input-number" [(ngModel)]="currency.value" mode="currency" [currency]="currency.currencyCode.toString()"></p-inputNumber>
                    </div>
                </div>
            </div>
        </section>
        <section class="grid align-items-center justify-content-start mt-5">
            <h5 class="col-2">Labels</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-4 epx-form-field">
                        <div class="epx-input-field">
                            <label>E-Mail</label>
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="col-2">Hints</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-4 epx-form-field">
                        <label>Some Field</label>
                        <div class="epx-input-field">
                            <input placeholder="col-4" type="text" pInputText />
                            <div class="epx-input-validations">
                                <small>Error Text</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="col-2">Button Additions</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-4 epx-form-field">
                        <label>Some Field</label>
                        <div class="epx-input-field p-input-icon-right">
                            <span class="epx-input-addition">
                                <p-button styleClass="epx-button epx-button-text">
                                    <epx-svg-icon [size]="16" color="Secondary" icon="check_mark"></epx-svg-icon>
                                </p-button>
                                <p-button styleClass="epx-button epx-button-text">
                                    <epx-svg-icon [size]="16" color="Secondary" icon="trash"></epx-svg-icon>
                                </p-button>
                            </span>
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                    <div class="col-4 epx-form-field">
                        <label>Some Field</label>
                        <div class="epx-input-field p-input-icon-left">
                            <span class="epx-input-addition">
                                <epx-svg-icon icon="search"></epx-svg-icon>
                            </span>
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                    <div class="col-4 epx-form-field">
                        <label>Some Field</label>
                        <div class="epx-input-field p-input-icon-right">
                            <input value="200" [readOnly]="true" placeholder="col-4" type="text" pInputText />
                            <span class="epx-input-addition">
                                <p-button styleClass="epx-button epx-button-text">
                                    <epx-svg-icon [size]="16" color="Secondary" icon="edit"></epx-svg-icon>
                                </p-button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="col-2">Checkboxes</h5>
            <div class="col-10 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-3 epx-form-field epx-form-field-row">
                        <p-checkbox (onChange)="checkboxChange($event)" styleClass="epx-checkbox" label="label" [(ngModel)]="checkbox" [binary]="true" inputId="binary"></p-checkbox>
                    </div>
                </div>
            </div>
            <h5 class="col-2">Row</h5>
            <div class="col-4 flex flex-row gap-2">
                <div class="grid w-full">
                    <div class="col-12 epx-form-field epx-form-field-row">
                        <label>Name</label>
                        <div class="epx-input-field">
                            <input placeholder="col-4" type="text" pInputText />
                            <div class="epx-input-validations">
                                <small>Error Text</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 epx-form-field epx-form-field-row">
                        <label>E-Mail</label>
                        <div class="epx-input-field">
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                    <div class="col-12 epx-form-field epx-form-field-row">
                        <label>Town</label>
                        <div class="epx-input-field">
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                    <div class="col-12 epx-form-field epx-form-field-row">
                        <label>Name des Unternehmens</label>
                        <div class="epx-input-field">
                            <input placeholder="col-4" type="text" pInputText />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="bg-white p-4 mt-4">
        <h3>Dropdown</h3>
        <section class="grid align-items-center justify-content-start mt-5">
            <h5 class="col-2">Basic</h5>
            <div class="col-4 flex flex-row gap-2">
                <p-dropdown styleClass="epx-dropdown" panelStyleClass="epx-dropdown-panel" [options]="dropdownMock" optionLabel="label" optionValue="value" [filter]="true" filterBy="label">
                </p-dropdown>
            </div>
        </section>
    </section>

    <section class="bg-white p-4 mt-4">
        <h3>Icons</h3>
        <section class="grid align-items-center justify-content-center mt-5">
            <h5 class="col-2">Primary</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Secondary</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Secondary'" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Success</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Success'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Success'" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Warning</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Warning'" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Error</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Error'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Error'" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Info</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Info'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Info'" icon="wallet"></epx-svg-icon>
            </div>
            <h5 class="col-2">Help</h5>
            <div class="col-10 flex flex-row gap-2">
                <epx-svg-icon [size]="24" [color]="'Help'" icon="appendix"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="arrow_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="arrow_reference"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="arrow_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="benefits"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="chevron_left"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="chevron_right"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="chevron_down"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="chevron_up"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="clock"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="company"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="dashboard"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="documents"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="download"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="edit"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="users"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="filter"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="home"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="import"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="logout"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="plus"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="profile"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="question_mark"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="reports"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="search"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="sparkle"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="subsidies"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="trash"></epx-svg-icon>
                <epx-svg-icon [size]="24" [color]="'Help'" icon="wallet"></epx-svg-icon>
            </div>
        </section>
    </section>
</section>
