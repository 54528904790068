const isInfo = (errorCode: number): boolean => {
    return errorCode >= 100 && errorCode < 200;
};

const isSuccess = (errorCode: number): boolean => {
    return errorCode >= 200 && errorCode < 300;
};

const isClientError = (errorCode: number): boolean => {
    console.error('Is Client Error', errorCode);
    return errorCode >= 400 && errorCode < 500;
};

const isApiError = (errorCode: number): boolean => {
    console.log(`Is Api Error: ${errorCode}`);
    return errorCode >= 500 && errorCode < 600;
};

export { isClientError, isApiError, isSuccess, isInfo };
