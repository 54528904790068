import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseErrorHandler } from '@core/interceptors/base.error-handler';
import { isApiError, isClientError } from '@shared/utils/error.handling.utils';

@Injectable()
export class ErrorCatchingInterceptor extends BaseErrorHandler implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                if (isApiError(error.error.statusCode)) return this.handleApiError(error);
                if (isClientError(error.error.statusCode)) this.handleClientError(error);
                return throwError(() => error);
            })
        );
    }
}
