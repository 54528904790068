import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LanguageService } from '@core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private languageService: LanguageService,
        private primeNgConfig: PrimeNGConfig
    ) {
        this.initTranslationService();
    }

    ngOnInit() {
        this.primeNgConfig.ripple = true;
    }

    protected initTranslationService() {
        this.languageService.initialLang();
    }
}
