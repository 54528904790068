enum ErrorMessages {
    GENERAL = 'GENERAL'
}

enum APIErrors {
    GENERAL = 'GENERAL_API_ERROR'
}

enum AuthErrors {
    LOGIN = 'AUTH.LOGIN',
    Wrong_Credentials = 'wrong-credentials'
}

enum TokenInterceptorMessages {
    SessionExpired = 'session-expired',
    NoSessionForToken = 'no-session-for-token',
    // eslint-disable-next-line quotes
    TokenMissing = "headers must have required property 'x-session-token'",
    TokenFormatWrong = 'headers/x-session-token must match format "uuid"'
}

export { AuthErrors, APIErrors, TokenInterceptorMessages, ErrorMessages };
