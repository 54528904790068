import { TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from '@app/app.module';
import { HttpClient } from '@angular/common/http';

export const ngxTranslateConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    },
    isolate: false,
    defaultLanguage: 'de'
};
