import { Injectable } from '@angular/core';
import { MessagesService } from '@services/messages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { stringIsEmpty } from '@sbfw/core';
import { APIErrors } from '@shared/enum';

const DEFAULT_REFRESH_URL_BLACKLIST = ['login', 'logout', 'token', 'assets', 'session-refresh'];

@Injectable({
    providedIn: 'root'
})
export class BaseErrorHandler {
    protected blackList: string[] = DEFAULT_REFRESH_URL_BLACKLIST;

    constructor(protected messageService: MessagesService) {}

    handleApiError(error: HttpErrorResponse) {
        const e = error.error;
        let message = this.getErrorMessage(error);
        stringIsEmpty(error.error.displayMessage) ? this.messageService.showError(message) : this.messageService.showErrorDialog({ message: e.displayMessage });
        return throwError(() => new Error(e));
    }

    handleClientError(error: HttpErrorResponse) {
        const e = error.error;
        let message = this.getErrorMessage(error);
        stringIsEmpty(e.displayMessage) ? this.messageService.showError(`ERROR_MESSAGES.${message}`.toUpperCase()) : this.messageService.showErrorDialog({ message: e.displayMessage });
        return throwError(() => new Error(e));
    }

    showErrorToast(message: string) {
        this.messageService.showError(`ERROR_MESSAGES.${message}`.toUpperCase());
    }

    protected getErrorMessage(error: HttpErrorResponse): string {
        return stringIsEmpty(error.error.displayMessage) ? `ERROR_MESSAGES.${APIErrors.GENERAL}` : error.error.displayMessage;
    }

    protected isBlacklisted(url: string) {
        return this.blackList.some((u) => url.includes(u));
    }
}
